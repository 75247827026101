'use client'
export const isValidAdClickProduct = inputEvent => {
  const { ecommerce, slug } = inputEvent

  const isAdClick = !!ecommerce?.click?.products?.[0]?.adId
  const isValidPage = ['/', '/search', '/categoryDetails'].includes(slug)

  return isAdClick && isValidPage
}

export const sendProductClick = function (payload) {
  window?._osAdClick?.({
    uclids: payload.uclids,
    cli_ubid: payload.cli_ubid,
  })
}
